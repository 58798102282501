import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  description: string
  image?: ImageProps
}

export const QualityMark = memo(function QualityMark({
  description,
  image,
}: Props) {
  if (!description) {
    return null
  }

  return (
    <Container>
      <FadeInUp>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </FadeInUp>
      {image ? (
        <FadeInUp>
          <Image {...image} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  max-width: 41.25rem;
  margin: 7.5rem auto 9.625rem;
  padding: 0 1.875rem;
  text-align: center;

  picture {
    img {
      height: auto !important;
      margin: 2.5rem auto 0;
      position: relative;
      top: auto;
      left: auto;
    }
  }

  @media (max-width: 767px) {
    max-width: none;
    margin: 3.75rem auto 7.5rem;
    padding: 0 1.25rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  letter-spacing: -0.0313rem;
  line-height: 2.25rem;
`
